<template>
    <div class="apaympro-container">

        <!-- Loader -->
        <loading :active.sync="isLoading" :can-cancel="false" :on-cancel="onCancel" :is-full-page="fullPage" loader="bars"
            :opacity="1"></loading>
        <!-- Loader -->

        <div class="apaympro-wrapper animated fadeIn container" style="width: 550px">
            <div class="apaympro-page">

                <!-- ****** -->
                <!-- ****** -->
                <!-- Header -->
                <!-- ****** -->
                <!-- ****** -->
                <div class="apaympro-header my-3" style="margin-top: 15px">
                    <div class="company-logo"
                        style="display: flex;justify-content: center; align-items: center; width: auto;">
                        <img src="@/assets/apaym-pay.png" width="200" alt="Logo APaym Pro" />
                    </div>
                    <div class="page-info">
                        <h3 class="page-name font-20 font-weight-bold">
                            TRANSFERT D'ARGENT
                        </h3>
                    </div>
                </div>
                <!-- ****** -->
                <!-- ****** -->
                <!-- Header -->
                <!-- ****** -->
                <!-- ****** -->


                <!-- **** -->
                <!-- **** -->
                <!-- Body -->
                <!-- **** -->
                <!-- **** -->
                <div class="body" v-if="show_response">
                    <form name="payment-form" class="payment-success animated fadeIn text-center">

                        <img v-if="response_orabank.statut === 'CAPTURED'" src="@/assets/confirmico.gif"
                            alt="Logo payment success" />
                        <img v-else src="@/assets/refusico.gif" alt="Logo payment error" />

                        <!-- ******************************** -->
                        <!-- ******************************** -->
                        <!-- La demande de transfert a abouti -->
                        <!-- ******************************** -->
                        <!-- ******************************** -->
                        <div v-if=" response_orabank.statut === 'CAPTURED' || response_orabank.statut === 'PURCHASED' || response_orabank.statut === 'AUTHORIZED' ">
                            <strong>
                                Votre demande de transfert a été effectuée avec succès !
                            </strong>

                            <br><br>
                            <span>
                                Transfert de
                            </span>
                            <h2 class="payment-success-message" style="font-weight: 900; font-size: 3rem;">
                                <strong>
                                    {{ new Intl.NumberFormat('fr-FR', {
                                        style: 'currency', currency: 'XOF'
                                    }).format(response_orabank.amount) }}
                                </strong>
                            </h2>
                            <div class="mb-3">
                                De la carte Ecobank
                                <strong>
                                    {{ response_orabank.customer_id }}
                                </strong> vers votre
                                <strong>
                                    {{ response_orabank.methode_retrait }}
                                </strong>
                            </div>
                            <p class="font-14 mt-5">
                                sera traité dans un délai de 24h à 48h
                            </p>
                        </div>
                        <!-- ******************************** -->
                        <!-- ******************************** -->
                        <!-- La demande de transfert a abouti -->
                        <!-- ******************************** -->
                        <!-- ******************************** -->

                        <!-- **************************************** -->
                        <!-- **************************************** -->
                        <!-- La demande de transfert a n'a pas abouti -->
                        <!-- **************************************** -->
                        <!-- **************************************** -->
                        <div v-else>
                            <strong>
                                Votre demande de transfert n'a pas abouti !
                            </strong>
                            <br><br>
                            <span style="font-style: italic;">
                                Motif: <strong> {{ motif }} </strong>
                            </span>
                            <br><br>
                            <span>
                                Transfert de
                            </span>
                            <h2 class="payment-success-message" style="font-weight: 900; font-size: 3rem;">
                                <strong>
                                    {{ new Intl.NumberFormat('fr-FR', {
                                        style: 'currency', currency: 'XOF'
                                    }).format(response_orabank.amount) }}
                                </strong>
                            </h2>
                            <div class="mb-3">
                                De la carte Ecobank
                                <strong>
                                    {{ response_orabank.customer_id }}
                                </strong> vers votre
                                <strong>
                                    {{ response_orabank.methode_retrait }}
                                </strong>
                            </div>
                        </div>
                        <!-- **************************************** -->
                        <!-- **************************************** -->
                        <!-- La demande de transfert a n'a pas abouti -->
                        <!-- **************************************** -->
                        <!-- **************************************** -->

                    </form>
                </div>

                <!-- ************************* -->
                <!-- ************************* -->
                <!-- Bouton retour à l'accueil -->
                <!-- ************************* -->
                <!-- ************************* -->
                <router-link to="/service/transfert" class="btn btn-dark btn-block mt-5">
                    Retour à l'accueil
                </router-link>
                <!-- ************************* -->
                <!-- ************************* -->
                <!-- Bouton retour à l'accueil -->
                <!-- ************************* -->
                <!-- ************************* -->

            </div>
        </div>
    </div>
</template>

<script>

// import { sweetAlert } from "@/constants/functions";
import axios from "axios";
import { apiUrl } from '@/constants/config';
// import Multiselect from "vue-multiselect";
// import json from "@/constants/flags.json";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

const orabank_status = {
    "CAPTURED": "Succès",
    "FAILED": "Echec de la transaction",
    "AWAIT_3DS": "En attente de la validation 3DS",
    "PENDING": "En attente de validation",
    "PURCHASED": "Succès",
    "AUTHORIZED": "Succès"
};

export default {
    created() {
    },
    components: {
        Loading,
    },
    data() {
        return {
            url_data: { reference: "" }, isLoading: false, fullPage: true, response_orabank: {}, show_response: false, motif: ""
        }
    },

    methods: {

        onCancel() {
            console.log('Loader dismiss');
        },

        getResponsePayment: function () {
            console.log(this.url_data.reference)

            this.isLoading = true;
            let dataSend = new FormData();
            dataSend.append("reference", this.url_data.reference);

            axios.post(apiUrl + 'api/retrait-ecobank/response',
                dataSend
            ).then(response => {

                this.isLoading = false;
                this.show_response = true;

                const { data } = response;
                this.response_orabank = { ...data }
                this.motif = orabank_status[data.statut]

            }).catch(error => {

                console.log(error);
                this.isLoading = false;
                this.$swal(
                    'Un erreur est survenue !',
                    error.response.data,
                    'error'
                );
            });
        },
    },

    mounted() {

        this.url_data.reference = this.$route.params.reference;
        if (!this.url_data.reference) {

            window.location.href = "https://pay.apaym.com/"
            return false;
        }

        this.getResponsePayment();
    }
}

</script>